.carousel {
  width: 100vw;
  height: 500px;
  z-index: 1;
}

.carousel-tile {
  position: relative;
  height: 500px;
  width: 100%;
  overflow: hidden;
}

.carousel-text {
  max-width: 50%;
}

.carousel-link {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  padding: 2rem 4rem 2rem 0;
}

.carousel-image {
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  z-index: -1;
}

.carousel-button {
  margin-top: 3rem;
}

@media only screen and (max-width: 768px) {

  .carousel-link {
    padding: 1rem .5rem;
  }

  .carousel-text {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    gap: 10px;
    max-width: 100%;
    margin-top: 6%;
    background-color: rgba(198, 195, 195, 0.9);
    border-radius: 15px;
    padding: 2%;
  }

  .carousel-title {
    font-size: 1.5rem;
    justify-content: end;
  }

  .carousel-subtitle {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    gap: 10px;
    max-width: 100%;
    margin-top: 2%;

  }

  .carousel-image {
    left: 0;
    width: 100%;
    object-fit: cover;
  }

  .carousel-button {
    position: absolute;
    /* right:   auto; */
    left: 5%;
    bottom: 7%;
  }
}