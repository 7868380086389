@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer base {
    html {
        @apply font-inter;
    }

    ol {
      list-style: revert;
      padding: revert;
      margin: revert;
    }

    body {
        background-color: #ECECEC;
    }

    @layer responsive {
        .make-a-shadow {
            text-shadow: .5px .5px 1px rgba(0, 0, 0, 0.75)
        }
    }

    a {
      color: rgb(28, 78, 164);
      text-decoration: underline;
    }
}